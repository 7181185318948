import env from '@beam-australia/react-env';
import { IdentifyParams, PageviewParams, TrackrParams } from './ITrackers';
import { Metrics } from '@sanardigital/analytics-tracker';
import { omit, pick } from 'ramda';
import { config } from 'contants/config';

export const omitUserProperties = [
    'name',
    'email',
    'creditCards',
    'phone_number',
    'card',
    'address',
];
export const pickAddressProperties = ['city_name', 'state_id'];

export const parseUser = (user: IdentifyParams['user']) => {
    if (!user) return {};

    const pickedAddress = user.address
        ? pick(pickAddressProperties, user.address)
        : null;
    return {
        ...omit(omitUserProperties, user),
        address: pickedAddress,
    };
};

export default {
    name: 'SanarAnalytics',
    init: () => {
        try {
            window.env = {
                AMPLITUDE_KEY: config.AMPLITUDE_KEY,
                MIXPANEL_KEY: env('MIXPANEL_KEY'),
            };
            Metrics.init();
        } catch (error) {
            console.error(error);
        }
    },
    track: (params: TrackrParams) => {
        try {
            const { eventName, additionalAttrs, user, eventType } = params;
            Metrics.sendEvent({
                application: 'sanarflix',
                eventName,
                eventType,
                properties: { ...additionalAttrs, ...parseUser(user) },
            });
        } catch (error) {
            console.error(error);
        }
    },

    pageview: ({ page, title, user }: PageviewParams) => {
        try {
            Metrics.sendEvent({
                eventName: 'page_view',
                eventType: 'page_view',
                application: 'sanarflix',
                properties: { page, title, ...parseUser(user) },
            });
        } catch (error) {
            console.error(error);
        }
    },

    identify: ({ user }: IdentifyParams) => {
        const parsedUser = parseUser(user);
        try {
            Metrics.identify(parsedUser);
        } catch (error) {
            console.error(error);
        }
    },
};
