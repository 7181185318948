import React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import env from '@beam-australia/react-env';

export const Feature = {
    COUPON: 'acquisitions_sanarflix_coupon',
    BLACK_FRIDAY: 'acquisitions_blackfriday',
    WINBACK_PROMO: 'acquisitions_winback_promo',
    DISABLE_MOBILE_FORM: 'acquisitions_checkout_disable_form_mobile',
    ACQUISITIONS_PROMO: 'acquisitions_promo',
};

const config = {
    url: env('UNLEASH_URL'),
    clientKey: env('UNLEASH_CLIENT_KEY'),
    refreshInterval: 60,
    appName: 'default',
    environment: 'default',
};

export const UnleashProvider = ({ children }) => (
    <FlagProvider config={config}>{children}</FlagProvider>
);
