export default {
    COUPON_FILLED: {
        action: 'filled',
        name: 'Coupon',
        input: 'coupon_input',
    },
    COUPON_VALIDATION: {
        action: 'validation',
        name: 'Coupon applied',
        cta: 'validate_button',
    },
    COUPON_REMOVED: {
        action: 'removed',
        name: 'Coupon applied',
        cta: 'remove_coupon_button',
    },
    COUPON_PURCHASE: {
        action: 'completed',
        name: 'Purchase with coupon',
        cta: 'next_button',
    },
    PAYMENT_METHOD_SELECTED: {
        action: 'clicked',
        name: 'Payment method',
        input: 'payment_method_input',
    },
    PAYMENT_METHOD_CHOSEN: {
        action: 'chosen',
        name: 'Payment method',
        cta: 'next_button',
    },
    QRCODE_COPY: {
        action: 'clicked',
        name: 'QRcode copy',
        cta: 'QRcode_copy_button',
    },
    QRCODE_PAGE: {
        action: 'view',
        name: 'QRcode page',
    },
    PURCHASE_COMPLETED: {
        action: 'completed',
        name: 'Purchase',
    },
    CONFIRM_PURCHASE: {
        action: 'clicked',
        name: 'Confirm purchase',
        cta: 'confirm_purchase_button',
    },
};
