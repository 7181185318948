import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';
import { Coupon } from 'utils/types/coupon';
import { PaymentCondition } from 'utils/types/paymentCondition';
import { Client } from 'utils/types/client';
import { newCheckoutInstance } from './instance';

export const getPaymentConditions = async (
    client: Client,
    planSlug: string,
    isWinback: boolean,
    isAmbassador: boolean,
    isBeginner: boolean,
    coupon?: Coupon
): Promise<PaymentCondition[]> => {
    const defaultParams = `?planSlug=${planSlug}&isWinback=${isWinback}&isAmbassador=${isAmbassador}&isBeginner=${isBeginner}`;
    const queryParams = coupon
        ? `${defaultParams}&couponValue=${coupon.value}&couponType=${coupon.couponType}`
        : defaultParams;

    try {
        const data = await newCheckoutInstance
            .get(`/v2/paymentConditions${queryParams}`, {
                headers: { Authorization: client.token },
            })
            .then(({ data }) => data.paymentConditions);

        return data;
    } catch (error) {
        logErrorOnNewRelic(error, {
            error: 'Error on getPaymentConditions request',
        });
        throw error;
    }
};
