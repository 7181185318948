export type PaymentCondition = {
    paymentMethod: PaymentMethod;
    installmentOptions: InstallmentOption[];
    tastingPeriodDays?: number;
    tastingPeriod?: TastingPeriod;
    installment?: number;
};

export enum PaymentMethod {
    PIX = 'pix',
    CREDIT_CARD = 'credit_card',
}

export type InstallmentOption = {
    installment: number;
    amount: number;
    discount: number;
};

export type TastingPeriod = {
    duration: number;
    price: number;
    endDate?: string;
    metadata?: Record<string, unknown>;
};
