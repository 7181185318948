import React, { forwardRef } from 'react';
import { SnackbarProvider } from 'notistack';

export { useSnackbar } from 'notistack';

export const FLXSnackbarProvider = forwardRef<any, any>(({ children }, ref) => {
    return (
        <SnackbarProvider
            ref={ref}
            preventDuplicate
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {children}
        </SnackbarProvider>
    );
});
