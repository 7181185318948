const breakpoints: any = ['30em', '48em', '62em', '80em'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const mediaQueries = Object.entries(breakpoints).reduce(
    (prev, [key, val]) => ({
        up: {
            ...prev.up,
            [key]: `@media screen and (min-width: ${val})`,
        },
        down: {
            ...prev.down,
            [key]: `@media screen and (max-width: ${val})`,
        },
    }),
    {
        up: {},
        down: {},
    }
);

export default breakpoints;
