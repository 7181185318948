import { logEvent } from 'services/lambda/log-event';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';
import { checkoutBffService } from './instance';
import { Campaign } from 'utils/types/campaign';

const path = '/setupClient';

export const getCampaign = async (
    campaignSlug: string
): Promise<Campaign | undefined> => {
    try {
        return (await checkoutBffService.get(`/v1/campaigns/${campaignSlug}`))
            .data;
    } catch (error) {
        logEvent({ error, path });
        logErrorOnNewRelic(error, {
            error: 'Error on getCampaign',
        });
    }
};

export const getEnableCampaign = async (
    campaignSlug: string
): Promise<Campaign | undefined> => {
    const campaign = await getCampaign(campaignSlug);
    return campaign?.status === 'enabled' ? campaign : undefined;
};
