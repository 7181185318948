import { config } from 'contants/config';
import GoogleAnalytics from './GoogleAnalytics';
import SanarAnalytics from './SanarAnalytics';

export const initializeTrackers = () => {
    /**
     * React Google Analytics 4 documentation
     * {@link https://github.com/codler/react-ga4}
     */
    GoogleAnalytics.init({
        key: config.GA_MEASUREMENT_ID,
        options: {},
    });

    SanarAnalytics.init();
};
