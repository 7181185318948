declare global {
    interface Window {
        newrelic: NewRelic.Browser;
    }
}

const hasNewRelicOnEnvironment = () =>
    typeof window !== 'undefined' && window.newrelic !== undefined;

const logErrorOnNewRelic = (
    error: any,
    customAttributes?: { [key: string]: string | number | undefined }
): void => {
    const responseCode = error?.response?.data?.code;
    const responseMessage = error?.response?.data?.message;
    if (hasNewRelicOnEnvironment()) {
        window.newrelic.noticeError(error, {
            responseCode,
            responseMessage,
            ...customAttributes,
        });
    }
};

export default logErrorOnNewRelic;
