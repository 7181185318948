import { Dialog } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
    div[role='dialog'].MuiPaper-rounded {
        border-radius: 16px;
    }
`;

export const CloseIcon = styled(MdClose)`
    width: 1.5rem;
    height: 1.5rem;
`;
