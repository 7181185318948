export const config = {
    AMPLITUDE_KEY: '950e1309f6dfd4d827d5524672ef1bb0',
    GA_MEASUREMENT_ID: 'G-Y6LEKXZD1K',
    'app-store-url':
        'https://apps.apple.com/br/app/sanarflix-estudar-medicina/id1440091219/?utm_source=home-sanarflix',
    'play-store-url':
        'https://play.google.com/store/apps/details?id=br.com.sanarflix&hl=pt-BR?utm_source=home-sanarflix',
    'whatsapp-link':
        'https://api.whatsapp.com/send?phone=5571986775003&text=Oi,%20tive%20problema%20na%20compra%20do%20meu%20plano,%20pode%20me%20ajudar?',
    'whatsapp-link-090':
        'https://api.whatsapp.com/send?phone=5571986775003&text=Oi,%20tive%20problema%20na%20compra%20do%20meu%20plano%20de%201,9,%20pode%20me%20ajudar?',
    'help-desk-url': 'https://ajuda.sanarflix.com.br/',
    'terms-of-use-url':
        'https://docs.google.com/document/d/e/2PACX-1vS05xaB433AuKJ8Mvz1a0GOpEFSvv1xviiwdBPu-E8l4zYSgp1rxxoss1i61bJ8TsJzUwqJMETizuUy/pub?https://sanarflix.com.br/home=',
    'policy-privacy-url':
        'https://docs.google.com/document/d/e/2PACX-1vS8YkxVcS1D5UUTALuJ54xDAJCirYmTUL5BaNfBuVfgZatmRa6W-jKq_WLNEa532iVj6YsO0M_81KOL/pub?embedded=true',
};
