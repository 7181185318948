const shadows = {
    xs: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08)',
    sm: '0px 3px 6px rgba(0, 0, 0, 0.06), 0px 0px 12px rgba(0, 0, 0, 0.04)',
    md: '0px 6px 12px rgba(0, 0, 0, 0.06), 0px 1px 15px rgba(0, 0, 0, 0.04)',
    lg: '0px 14px 28px rgba(0, 0, 0, 0.06), 0px 2px 18px rgba(0, 0, 0, 0.04)',
    xl: '0px 20px 40px rgba(0, 0, 0, 0.06), 0px 8px 36px rgba(0, 0, 0, 0.04)',
    none: 'none',
};

export type Shadows = typeof shadows;

export default shadows;
