import { Order } from 'utils/types/order';
import { Plan } from 'utils/types/plan';
import { PaymentCondition } from 'utils/types/paymentCondition';

export const getOrder = (
    plans: Plan[],
    planType: string,
    isAmbassador: boolean,
    payment?: PaymentCondition,
    paymentConditions?: PaymentCondition[]
): Order => {
    const plan = plans[0];

    return {
        plan,
        planType,
        isAmbassador,
        payment,
        paymentConditions,
    };
};
