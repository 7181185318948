import env from '@beam-australia/react-env';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';
import { Client } from 'utils/types/client';
import { Plan } from 'utils/types/plan';

import { newCheckoutInstance } from './instance';

const path = '/getPlans';

export const getPlans = async (
    client: Client,
    planType: string,
    isAmbassador: boolean
): Promise<Plan[]> => {
    try {
        const planSlug = convertPlanTypeToPlanSlug(planType);

        const data = await newCheckoutInstance
            .get(`${path}?planSlug=${planSlug}&isAmbassador=${isAmbassador}`, {
                headers: { Authorization: client.token },
            })
            .then(({ data }) => data);

        return data.plans;
    } catch (error) {
        logErrorOnNewRelic(error, {
            error: 'Error on getPlans request',
        });
        throw error;
    }
};

export const convertPlanTypeToPlanSlug = (planType: string) => {
    return `${env('PLAN_SLUG_PREFIX')}${planType}`;
};
