export enum EStepsMobile {
    INITIAL = 0,
    EMAIL = 1,
    EMAIL_CONFIRMATION = 2,
    NAME = 3,
    INGRESS_YEAR = 4,
    CPF = 5,
    PAYMENT = 6,
}

export enum EStepsResponsiveWeb {
    INITIAL = 0,
    PROCESSING = 1,
    PAYMENT = 2,
}

export enum EStepsDesktop {
    INITIAL = 0,
    PROCESSING = 1,
}

export enum EPaymentSteps {
    SELECT_METHOD = 0,
    PAYMENT_FORM = 1,
    PAYMENT_DETAILS = 2,
}
