export type Coupon = {
    value: number;
    code: string;
    couponType: DiscountType;
    status?: CouponStatus;
};

export enum DiscountType {
    PERCENTAGE = 'percentage',
    AMOUNT = 'amount',
}

export enum CouponStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}
