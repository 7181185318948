import MobileDetect from 'mobile-detect';

export const checkMobile = (
    userAgent: string,
    positive: any,
    negative: any
) => {
    const md = new MobileDetect(userAgent);
    return !!md.mobile() ? positive : negative;
};
