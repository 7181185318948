import { tint, shade } from 'polished';

export const getVariantColor = ({ theme, variantColor }: any) => {
    if (!!theme.colors[variantColor]) {
        return theme.colors[variantColor];
    }

    if (variantColor.includes('.')) {
        const [color, tone] = variantColor.split('.');

        if (!!theme.colors[color][tone]) {
            return theme.colors[color][tone];
        }
    }

    return variantColor;
};

export const getColorVariantLight = (main, percentage = 0.6) =>
    tint(percentage, main);

export const getColorVariantDark = (main, percentage = 0.4) =>
    shade(percentage, main);
