import * as Sentry from '@sentry/browser';

import env from '@beam-australia/react-env';

export const logEvent = ({ path, error }) => {
    console.log(
        `Error ${path} - [code]:`,
        JSON.stringify(error?.response || error)
    );

    const code = error?.response?.data?.code || 'without code';
    const message = error?.response?.data?.message || 'no message';

    if (env('SENTRY_DSN')) {
        Sentry.captureException(error, (scope) => {
            scope.setExtra(`Error ${path} #${code}`, message);
            scope.setTag(code, message);
            return scope;
        });
    }
};

export async function addsUserContext(email: string) {
    if (env('SENTRY_DSN')) {
        Sentry.setUser({
            email,
        });
    }
}

export const initializeSentry = () => {
    if (env('SENTRY_DSN') && env('ENVIRONMENT') === 'prod') {
        Sentry.init({
            dsn: env('SENTRY_DSN'),
        });
    }
};
