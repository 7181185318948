import React from 'react';
import { Typography } from '@material-ui/core';
import { Box } from 'components/Box';
import { CloseIcon, StyledDialog } from './styles';
import { testID } from 'utils/common/domHelper';
import { ModalContent } from 'utils/types/modal-contents';
import { Button } from '../Button';

interface IModalProps {
    title: string;
    isOpen: boolean;
    description?: string;
    buttonProps?: { text: string };
    onClose: () => void;
    contents: ModalContent[];
}

export const Modal = ({
    title,
    isOpen,
    contents,
    description,
    buttonProps,
    onClose,
}: IModalProps) => {
    return (
        <StyledDialog
            onClose={onClose}
            open={isOpen}
            PaperProps={{ elevation: 2 }}
        >
            <Box padding={8} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                    <Typography style={{ fontSize: '20px', fontWeight: 700 }}>
                        {title}
                    </Typography>
                    <CloseIcon
                        onClick={onClose}
                        cursor='pointer'
                        {...testID('modal-close')}
                    />
                </Box>
                {description && (
                    <Typography style={{ fontSize: '16px' }}>
                        {description}
                    </Typography>
                )}

                <Box
                    display='flex'
                    flexDirection='column'
                    paddingTop={4}
                    style={{ gap: '1.5rem' }}
                    marginBottom='1.5rem'
                >
                    {contents.map((content) => {
                        return (
                            <Box
                                backgroundColor='#EDF2FD'
                                padding={6}
                                borderRadius={8}
                                key={content.emoji}
                            >
                                <Box display='flex' style={{ gap: '1.5rem' }}>
                                    <Box as='img' src={content.emoji} />
                                    <Typography
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {content.text}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                {buttonProps && (
                    <Button
                        variantColor='#0050F0'
                        textTransform='capitalize'
                        onClick={onClose}
                        {...testID('button-modal-close')}
                    >
                        {buttonProps.text}
                    </Button>
                )}
            </Box>
        </StyledDialog>
    );
};
