export const PURCHASE_REJECTED = {
    code: 712,
    message: 'PURCHASE_REJECTED',
};
export const HAS_ACTIVE_SUBCRIPTION = {
    code: 730,
    message: 'HAS_ACTIVE_SUBCRIPTION',
};
export const HAS_INACTIVE_SUBSCRIPTIONS = {
    code: 731,
    message: 'HAS_INACTIVE_SUBSCRIPTIONS',
};
export const USER_ERROR = {
    code: 732,
    message: 'USER_ERROR',
};

export const NEW_PAYMENTS_ENTITY_ERROR = {
    code: 750,
    message: 'NEW_PAYMENTS_ENTITY_ERROR',
};
