import React, { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { switchProp, theme, ifProp, prop } from 'styled-tools';
import { space, border, SpaceProps, BorderProps } from 'styled-system';

import { Text } from 'components/Text';

import {
    getColorVariantDark,
    getColorVariantLight,
    getVariantColor,
} from 'utils/theme/colors';

type Variant = 'square' | 'solid' | 'outline' | 'ghost' | 'link';
type Size = 'xs' | 'sm' | 'md' | 'lg';

export type ButtonProps = {
    variant?: Variant;
    variantColor?: string;
    size?: Size;
    block?: boolean;
    fab?: boolean;
    textTransform?: React.CSSProperties['textTransform'];
    as?: any;
    href?: string;
    icon?: React.ReactNode;
    loading?: boolean;
} & SpaceProps &
    BorderProps &
    React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.LinkHTMLAttributes<HTMLLinkElement>;

const TextButton = styled(Text)``;

const ButtonSizes = {
    sm: css`
        height: 32px;
    `,

    md: css`
        height: 40px;
    `,

    lg: css`
        height: 48px;
    `,
};

const ButtonVariantsColor = {};

const ButtonVariants = {
    solid: css<ButtonProps>`
        border-color: ${getVariantColor};
        background-color: ${getVariantColor};

        > ${TextButton}, > svg,
        > circle {
            color: ${theme('colors.white')};
            stroke: ${theme('colors.white')};
        }

        &:hover {
            border-color: ${(props) =>
                getColorVariantDark(getVariantColor(props))};
            background-color: ${(props) =>
                getColorVariantDark(getVariantColor(props))};
        }

        &:disabled {
            cursor: not-allowed;
            border-color: ${theme('colors.gray.100')};
            background-color: ${theme('colors.gray.100')};

            > ${TextButton}, > svg,
            > circle {
                color: ${theme('colors.gray.200')};
                stroke: ${theme('colors.gray.200')};
            }
        }
    `,
    square: css<ButtonProps>`
        border-color: ${getVariantColor};
        background-color: ${getVariantColor};
        border-radius: 4px;

        > ${TextButton}, > svg,
        > circle {
            color: ${theme('colors.white')};
            stroke: ${theme('colors.white')};
        }

        &:hover {
            border-color: ${(props) =>
                getColorVariantDark(getVariantColor(props))};
            background-color: ${(props) =>
                getColorVariantDark(getVariantColor(props))};
        }

        &:disabled {
            cursor: not-allowed;
            border-color: ${theme('colors.gray.100')};
            background-color: ${theme('colors.gray.100')};

            > ${TextButton}, > svg,
            > circle {
                color: ${theme('colors.gray.200')};
                stroke: ${theme('colors.gray.200')};
            }
        }
    `,

    outline: css<ButtonProps>`
        border-color: ${getVariantColor};

        > ${TextButton}, > svg,
        > circle {
            color: ${getVariantColor};
            stroke: ${(props) => getColorVariantLight(getVariantColor(props))};
        }

        &:hover:not(:disabled) {
            border-color: ${(props) =>
                getColorVariantDark(getVariantColor(props))};
            background-color: ${(props) =>
                getColorVariantLight(getVariantColor(props), 0.9)};
        }

        &:disabled {
            cursor: not-allowed;
            border-color: ${theme('colors.gray.100')};
            background-color: ${theme('colors.gray.100')};

            > ${TextButton}, > svg,
            > circle {
                color: ${theme('colors.gray.200')};
                stroke: ${theme('colors.gray.200')};
            }
        }
    `,

    ghost: css<ButtonProps>`
        > ${TextButton}, > svg,
        > circle {
            color: ${getVariantColor};
            stroke: ${(props) => getColorVariantLight(getVariantColor(props))};
        }

        &:hover:not(:disabled) {
            background-color: ${(props) =>
                getColorVariantLight(getVariantColor(props), 0.9)};
        }

        &:disabled {
            cursor: not-allowed;
            background-color: ${theme('colors.gray.100')};

            > ${TextButton}, > svg,
            > circle {
                color: ${theme('colors.gray.200')};
                stroke: ${theme('colors.gray.200')};
            }
        }
    `,

    link: css<ButtonProps>`
        > ${TextButton}, > svg,
        > circle {
            color: ${getVariantColor};
            stroke: ${(props) => getColorVariantLight(getVariantColor(props))};
        }

        &:hover:not(:disabled) {
            text-decoration: underline;
        }

        &:disabled {
            cursor: not-allowed;

            > ${TextButton}, > svg,
            > circle {
                color: ${theme('colors.gray.200')};
                stroke: ${theme('colors.gray.200')};
            }
        }
    `,
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(AiOutlineLoading3Quarters)`
    animation: ${rotate} 1s linear infinite;
`;

const ButtonStyled = styled.button<ButtonProps>`
    ${space};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: ${theme('radii.md')};
    text-decoration: none;
    font-weight: bold;
    text-transform: ${prop('textTransform', 'uppercase')};
    padding-left: ${theme('sizes.5')};
    padding-right: ${theme('sizes.5')};

    &:focus {
        outline: none;
        border-color: ${(props) =>
            getColorVariantLight(getVariantColor(props))};
        box-shadow: inset 0px 0px 0px 1px
            ${(props) => getColorVariantLight(getVariantColor(props))};

        &:hover {
            box-shadow: none;
        }
    }

    svg {
        cursor: pointer;
        margin-left: -1px;
        margin-right: -1px;
    }

    position: relative;
    overflow: hidden;

    ${ifProp(
        'block',
        css`
            width: 100%;
            justify-content: center;
        `
    )};

    ${border};

    ${switchProp('size', ButtonSizes, ButtonSizes.md)};
    ${switchProp('variant', ButtonVariants, ButtonVariants.solid)};
    ${switchProp('variantColor', ButtonVariantsColor)};
`;

export const Button: React.FC<ButtonProps> = ({
    children,
    variantColor = 'primary',
    icon: iconProp,
    loading,
    disabled,
    ...props
}) => {
    const icon = useMemo(() => {
        if (loading) {
            return (
                <TextButton
                    key='loading'
                    display='inline-flex'
                    mr={children ? '2' : '0'}
                >
                    <LoadingIcon />
                </TextButton>
            );
        }

        if (!iconProp) {
            return null;
        }

        return (
            <TextButton display='inline-flex' mr={children ? '2' : '0'}>
                {iconProp}
            </TextButton>
        );
    }, [iconProp, children, loading]);

    return (
        <ButtonStyled
            {...props}
            disabled={disabled || loading}
            variantColor={variantColor}
        >
            {icon}
            {children && <TextButton>{children}</TextButton>}
        </ButtonStyled>
    );
};
