export type User = {
    email: string;
    subscription: Subscription;
    name?: string;
    phone?: {
        countryCode: string;
        number: string;
    };
    id?: string;
    nationalId?: string;
    metadata?: UserMetadata;
};

export type UserMetadata = {
    ingressYear: number | string;
    ingressSemester: number | string;
};

export type Subscription = {
    id: string;
    status: string;
    platformId: string;
    hasPreviousSubscription: boolean;
};

export enum UserType {
    BEGINNER = 'beginner',
    WINBACK = 'winback',
    AMBASSADOR = 'ambassador',
    DEFAULT = 'default',
}
