import ReactGA from 'react-ga4';

import {
    IdentifyParams,
    TrackrParams,
    InitParams,
    PageviewParams,
} from './ITrackers';

export default {
    name: 'GoogleAnalytics',
    init: ({ key, options }: InitParams) => {
        try {
            ReactGA.initialize([key, options]);
        } catch (error) {
            console.error(error);
        }
    },

    track: (params: TrackrParams) => {
        try {
            const { eventName } = params;
            ReactGA.event(eventName);
        } catch (error) {
            console.error(error);
        }
    },

    pageview: ({ page, title }: PageviewParams) => {
        try {
            ReactGA.send({ hitType: 'pageview', page: page, title: title });
        } catch (error) {
            console.error(error);
        }
    },

    identify: ({ user }: IdentifyParams) => {
        try {
            const userId = user?.id;
            ReactGA.set({ userId });
        } catch (error) {
            console.error(error);
        }
    },
};
