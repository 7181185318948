import { createContext, useContext, useState } from 'react';
import { Modal } from '../components/Modal';
import { ModalContent } from '../utils/types/modal-contents';

interface ModalProps {
    title?: string;
    subtitle?: string;
    contents: ModalContent[];
    onClose?: () => void;
    buttonProps?: { text: string };
}
interface ModalProviderValues {
    openModal: (modalProps: ModalProps) => void;
    closeModal: (cb?: () => void) => void;
}

export const ModalContext = createContext<ModalProviderValues>(
    {} as ModalProviderValues
);

export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
    const [modalProps, setModalProps] = useState<ModalProps>(null);

    const openModal = (props: ModalProps) => setModalProps(props);

    const closeModal = (cb?: () => void) => {
        if (cb) cb();
        setModalProps(null);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {!!modalProps && (
                <Modal
                    isOpen={!!modalProps}
                    onClose={() => closeModal(modalProps.onClose)}
                    title={modalProps.title}
                    description={modalProps.subtitle}
                    contents={modalProps.contents}
                    buttonProps={modalProps.buttonProps}
                />
            )}
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
