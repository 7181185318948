import React, { createContext, useContext, useMemo } from 'react';
import env from '@beam-australia/react-env';
import { useRouter } from 'next/router';

import {
    IdentifyParams,
    InitParams,
    PageviewParams,
    TrackrParams,
} from '../Config/Trackers/ITrackers';

interface Metrics {
    sendMetric: (params: TrackrParams) => void;
    identify: (params: IdentifyParams) => void;
    pageview: (params: PageviewParams) => void;
}

type Tracker = {
    init: (oarams: InitParams) => void;
    track?: (params: TrackrParams) => void;
    identify: (params: IdentifyParams) => void;
    pageview?: (params: PageviewParams) => void;
    name?: string;
};

type FLXMetricsProps = {
    trackers: Tracker[];
    children?: React.ReactNode;
};

export const Context = createContext<Metrics>({
    identify: () => {
        console.error('Identify not provided');
    },
    sendMetric: () => {
        console.error(
            'Send metric provider was not specified, use <MetricsContext.Provider /> in your app code'
        );
    },
    page: () => {
        console.error('Send metric provider was not specified');
    },
} as any);

export const useMetricsContext = () => useContext(Context);

export const FLXMetrics: React.FC<FLXMetricsProps> = ({
    children,
    trackers,
}) => {
    const router = useRouter();
    const contextValue = useMemo(
        () => ({
            sendMetric: ({
                eventName,
                additionalAttrs,
                eventProperties,
                userProperties,
                newTrackerProperties,
            }: TrackrParams) => {
                const trackData = {
                    eventName,
                    additionalAttrs: {
                        ...eventProperties,
                        ...userProperties,
                        ambassador_id: router.query.imb_id,
                        user_type: router.query.user_type,
                        experimentName: router.query.experimentName,
                        experimentVariant: router.query.experimentVariant,
                        environment: env('ENVIRONMENT'),
                        name: additionalAttrs?.name || '',
                        data: {
                            ...additionalAttrs?.data,
                            ...router,
                            page_title: document.title,
                            application: 'sanarflix',
                        },
                    },
                };

                const newTrackData = {
                    eventName: `${newTrackerProperties?.name}: ${newTrackerProperties?.action}`,
                    additionalAttrs: {
                        ...newTrackerProperties?.eventProperties,
                        experimentName: router.query.experimentName,
                        experimentVariant: router.query.experimentVariant,
                        ambassador_id: router.query.imb_id,
                        user_type: router.query.user_type,
                        environment: env('ENVIRONMENT'),
                        product: 'sanarflix-default',
                        page: location.pathname,
                    },
                };

                trackers.forEach((tracker) => {
                    tracker.track?.(
                        !newTrackerProperties ? trackData : newTrackData
                    );
                    console.groupEnd();
                });
            },

            identify: ({ user }: IdentifyParams) => {
                trackers.forEach((tracker) => {
                    tracker.identify({ user: user });
                });
            },

            pageview: (params: PageviewParams) => {
                trackers.forEach((tracker) => {
                    tracker.pageview?.(params);
                });
            },
        }),
        [trackers, router]
    );

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
