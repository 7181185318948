import {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react';

import { useRouter } from 'next/router';
import { useMetricsContext } from 'hooks/metrics';
import { getPlans } from 'services/lambda/plan';
import { getOrder } from 'services/lambda/order';
import { getClient } from 'services/lambda/client';
import { getPaymentConditions } from 'services/lambda/payment-conditions';
import { User, UserType } from 'utils/types/user';
import { EStepsDesktop, EStepsMobile } from 'utils/enums/steps';
import { Coupon, CouponStatus } from 'utils/types/coupon';
import { EcommerceCoupon } from 'utils/types/ecommerce';
import { Field } from 'utils/types/field';
import { Infos } from 'utils/types/infos';
import { Order } from 'utils/types/order';
import { Plan } from 'utils/types/plan';
import { PaymentCondition, PaymentMethod } from 'utils/types/paymentCondition';
import eventsMap from 'constants/events';
import { useVariant } from '@unleash/proxy-client-react';
import { Feature } from '../hooks/unleash';
import { getEnableCampaign } from 'services/checkout-bff/get-campaign';

interface Props {
    children?: React.ReactNode;
}

interface UpdateOrderParams {
    isWinback?: boolean;
    isBeginner?: boolean;
    coupon?: Coupon;
}

type PageProviderValues = {
    stepMobile: number;
    setStepMobile: React.Dispatch<React.SetStateAction<number>>;
    stepWeb: number;
    setStepWeb: React.Dispatch<React.SetStateAction<number>>;
    fields: Field;
    setFields: React.Dispatch<React.SetStateAction<Field>>;
    infos: Infos;
    setInfos: React.Dispatch<React.SetStateAction<Infos>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    ecommerceCoupon: EcommerceCoupon;
    setEcommerceCoupon: React.Dispatch<React.SetStateAction<EcommerceCoupon>>;
    coupon: Coupon;
    applyCoupon: (coupon: Coupon) => void;
    resetCoupon: () => void;
    updateOrder: (params: UpdateOrderParams) => Promise<PaymentCondition[]>;
    resetSteps: () => void;
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    order: Order;
    setOrder: React.Dispatch<React.SetStateAction<Order>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    onTrack: (
        eventKey: string,
        additionalAttrs?: { [key: string]: unknown }
    ) => void;
    isPixAvailable: boolean;
    triggerSetup: () => Promise<{
        client: any;
        userAgent: string;
        plans: Plan[];
        paymentConditions: PaymentCondition[];
    }>;
};

export const PageContext = createContext<PageProviderValues>(
    {} as PageProviderValues
);

export const usePageContext = () => useContext(PageContext);

export const PageProvider: React.FC<Props> = ({ children }) => {
    const { query, push } = useRouter();
    const [stepMobile, setStepMobile] = useState(0);
    const [stepWeb, setStepWeb] = useState(0);
    const [user, setUser] = useState<User>();
    const [fields, setFields] = useState<Field>();
    const [infos, setInfos] = useState<Infos>({} as Infos);
    const [order, setOrder] = useState<Order>(undefined);
    const [email, setEmail] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [coupon, setCoupon] = useState<Coupon>(undefined);
    const [ecommerceCoupon, setEcommerceCoupon] =
        useState<EcommerceCoupon>(undefined);
    const [originalPaymentConditions, setOriginalPaymentConditions] =
        useState<PaymentCondition[]>();

    const { sendMetric } = useMetricsContext();

    const { payload } = useVariant(Feature.WINBACK_PROMO);

    const isPixAvailable = useMemo(() => {
        return !!order?.paymentConditions.find(
            (condition) => condition.paymentMethod === PaymentMethod.PIX
        );
    }, [order?.paymentConditions]);

    const eventProperties = useMemo(
        () => ({
            payment_method: order?.payment?.paymentMethod,
            plan_slug: order?.plan.slug,
            coupon_code: coupon?.code,
            coupon_validation: coupon?.status,
            user_type: order?.isWinback ? 'winback' : 'new user',
            installments: fields?.installments,
        }),
        [order, coupon, fields]
    );

    const userProperties = useMemo(
        () => ({
            ingress_year: fields?.year,
            subscription_plan: order?.plan.slug,
        }),
        [fields, order]
    );

    const onTrack = (
        eventKey: string,
        additionalAttrs?: { [key: string]: unknown }
    ) => {
        const event = eventsMap[eventKey];
        sendMetric({
            eventName: event.name,
            newTrackerProperties: {
                ...event,
                eventProperties: {
                    cta: event.cta,
                    input: event.input,
                    ...eventProperties,
                    ...userProperties,
                    ...additionalAttrs,
                },
            },
        });
    };

    const resetSteps = () => {
        setStepMobile(EStepsMobile.INITIAL);
        setStepWeb(EStepsDesktop.INITIAL);
    };

    const resetCoupon = () => {
        setCoupon(null);
        setOrder((prev) => ({
            ...prev,
            payment: getPayment(),
            paymentConditions: originalPaymentConditions,
        }));
    };

    const applyCoupon = async (coupon: Coupon) => {
        setCoupon(coupon);

        await updateOrder({ coupon });
    };

    const planType = query?.plan as string;
    const isAmbassador = !!query?.imb_id;
    const userType = query?.user_type?.toString() as UserType;
    const campaignSlug = query?.campaign as string;

    const setup = useCallback(
        async (
            planType: string,
            isAmbassador: boolean,
            isWinback: boolean,
            isBeginner: boolean,
            campaignSlug: string
        ) => {
            const userAgent = window?.navigator?.userAgent;

            const client = await getClient(userAgent);
            const plans = await getPlans(client, planType, isAmbassador);

            let campaign;

            if (campaignSlug) {
                campaign = await getEnableCampaign(campaignSlug);
            }

            const paymentConditions = await getPaymentConditions(
                client,
                plans?.[0]?.slug,
                isWinback,
                isAmbassador,
                isBeginner
            );

            return { client, userAgent, plans, paymentConditions, campaign };
        },
        []
    );

    const triggerSetup = async () =>
        await setup(
            planType,
            isAmbassador,
            order?.isWinback,
            userType === UserType.BEGINNER,
            campaignSlug
        );

    const updateOrder = useCallback(
        async ({ isWinback, isBeginner, coupon }: UpdateOrderParams) => {
            if (!infos || !order || coupon?.status === CouponStatus.ERROR)
                return;

            const paymentConditions = await getPaymentConditions(
                infos.client,
                order.plan.slug,
                isWinback ?? order.isWinback,
                order.isAmbassador,
                isBeginner ?? order.isBeginner,
                coupon
            );

            if (paymentConditions?.length > 0) {
                setOrder((prev) => ({
                    ...prev,
                    isWinback: isWinback,
                    isBeginner: isBeginner,
                    couponCode: coupon?.code,
                    payment: getPayment(
                        paymentConditions,
                        PaymentMethod.CREDIT_CARD
                    ),
                    paymentConditions,
                }));

                setOriginalPaymentConditions(paymentConditions);

                return paymentConditions;
            }
        },
        [infos, order]
    );

    const getPayment = (
        paymentConditions?: PaymentCondition[],
        paymentMethod?: PaymentMethod
    ) => {
        const conditions = paymentConditions || originalPaymentConditions;
        return conditions?.find(
            (payment) =>
                payment?.paymentMethod ===
                (paymentMethod || fields?.paymentMethod)
        );
    };

    const getActiveCampaign = () => {
        const { campaign, plans } = JSON.parse(payload?.value || '{}') as {
            campaign?: string;
            plans?: string[];
        };

        if (!order.isWinback || !plans?.includes(order.plan.slug)) {
            return undefined;
        }

        if (fields?.paymentMethod !== PaymentMethod.CREDIT_CARD) {
            return undefined;
        }

        return campaign;
    };

    useEffect(() => {
        if (!query.plan || query.plan === order?.planType) return;

        setup(
            planType,
            isAmbassador,
            order?.isWinback,
            userType === UserType.BEGINNER,
            campaignSlug
        )
            .then(({ plans, paymentConditions, campaign, ...data }) => {
                setInfos((prev) => ({ ...prev, ...data }));
                setOriginalPaymentConditions(paymentConditions);
                setOrder((prev) => ({
                    ...prev,
                    ...getOrder(
                        plans,
                        planType,
                        isAmbassador,
                        getPayment(paymentConditions),
                        paymentConditions
                    ),
                    campaign,
                }));
            })
            .catch(() => {
                push('/falha');
            });
    }, [setup, query.plan]);

    useEffect(() => {
        if (!order?.planType) return;

        if (
            order.planType !== 'mensal' &&
            stepMobile === EStepsMobile.INITIAL
        ) {
            setStepMobile(EStepsMobile.EMAIL);
        }
    }, [order?.planType, stepMobile]);

    useEffect(() => {
        if (!fields?.paymentMethod) return;
        setOrder((prev) => ({
            ...prev,
            payment: { ...getPayment(), installment: fields?.installments },
            metadata: {
                ...prev.metadata,
                ...(userType && { userType }),
                campaign: prev.metadata?.campaign || getActiveCampaign(),
            },
        }));
    }, [fields]);

    const value = {
        stepMobile,
        setStepMobile,
        stepWeb,
        setStepWeb,
        fields,
        setFields,
        infos,
        setInfos,
        email,
        setEmail,
        coupon,
        resetCoupon,
        applyCoupon,
        resetSteps,
        updateOrder,
        setUser,
        user,
        order,
        setOrder,
        errorMessage,
        setErrorMessage,
        ecommerceCoupon,
        setEcommerceCoupon,
        onTrack,
        isPixAvailable,
        triggerSetup,
    };

    return (
        <PageContext.Provider value={value}>{children}</PageContext.Provider>
    );
};
