import env from '@beam-australia/react-env';

const windowPageTitle = `${
    !env('ENVIRONMENT') || env('ENVIRONMENT') === 'prod'
        ? ''
        : `[${env('ENVIRONMENT')}]`
} %s | Checkout`;

export default {
    title: 'SanarFlix',
    titleTemplate: windowPageTitle,
    description: 'Pagamento SanarFlix',
    additionalMetaTags: [
        { property: 'author', content: 'sanarflix' },
        {
            property: 'keywords',
            content: 'Sanar, Saúde, SanarFlix, Aulas, Pagamento, Assine',
        },
    ],
    twitter: {
        cardType: 'summary_large_image',
        handle: '@sanarflix',
        site: '@sanarflix',
    },
    openGraph: {
        type: 'website',
        locale: 'pt_BR',
        site_name: 'SanarFLix',
        images: [
            {
                url: 'https://plataforma-sanarflix.s3-sa-east-1.amazonaws.com/logos/logo-inline.png',
                width: 240,
                height: 40,
                alt: 'Logo',
            },
        ],
    },
};
