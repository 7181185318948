import { newCheckoutInstance } from 'services/lambda/instance';
import { logEvent } from 'services/lambda/log-event';
import { PURCHASE_REJECTED } from 'contants/errors';
import { getSource } from 'services/lambda/get-source';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';

const path = '/setupClient';

export const getClient = async (userAgent: string) => {
    try {
        const data = await newCheckoutInstance
            .post(path, {
                source: getSource(userAgent),
            })
            .then(({ data }) => data);

        return data;
    } catch (error) {
        logEvent({ error, path });
        logErrorOnNewRelic(error, {
            error: 'Error on getClient',
        });
        throw error?.response?.data?.code || PURCHASE_REJECTED.code;
    }
};
